import { CUSTOMER_ROUTES } from "./FrontendRoutes";
import visaLogo from "../images/visa.svg";
import masterLogo from "../images/mastercard.svg";
import americanExpressCard from "../images/americanExpressCard.svg";
import discover from "../images/discover.svg";
import dinersClub from "../images/diners.svg";
import unionPay from "../images/unionpay.svg";

export const CARD_NETWORK = {
  visa: { title: "Visa", logo: visaLogo },
  mastercard: { title: "Master Card", logo: masterLogo },
  amex: { title: "American Express", logo: americanExpressCard },
  discover: { title: "Discover", logo: discover },
  diners: { title: "Diners Club", logo: dinersClub },
  unionpay: { title: "UnionPay", logo: unionPay },
};


export const COBLRSHOP_PRODUCT_FIELDS = [
  "Status",
  "Date of laste order",
  "Item name",
  "Brand",
  "Color",
  "Description",
  "Cost per item",
  "Sales price",
  "Stock",
  "Inventory",
  "Reorder level",
];

export const CUSTOMER_FIELDS = {
  NAME: "Full name",
  EMAIL: "Email",
  PHONE_NUMBER: "Phone number",
  SIGNUP_DATE: "Last signup",
  SHIPPING_ADDRESS: "Shipping address",
  BILLING_ADDRESS: "Billing address",
};

export const MONTHS_IN_YEAR = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const SERVICE_TYPE = Object.freeze({
  upper_repair: "Upper Repair",
  sole_repair: "Sole Repair",
  other_shoe_repairs: "Other Shoe Repairs",
  more_leather_repairs: "More Leather Repairs",
});

export const CATEGORY = Object.freeze({
  women_shoes: "Women's Shoes",
  mens_shoes: "Men's Shoes",
  handbags: "Handbags",
  leather_care: "Leather Care",
  accessories: "Accessories",
  other: "Other",
});

export const COMMON_STATUS = Object.freeze({
  active: "Active",
  in_active: "Inactive",
});

export const USER_ROLES = Object.freeze({
  CUSTOMER: "customer",
  ADMIN: "admin",
  MANAGER: "manager",
  REPAIR_TEAM: "repair_team",
  CORPORATE: "corporate"
});

export const USER_ROLES_MAPPER = Object.freeze({
  [USER_ROLES.CUSTOMER]: "Customer",
  [USER_ROLES.ADMIN]: "Admin",
  [USER_ROLES.MANAGER]: "Manager",
  [USER_ROLES.REPAIR_TEAM]: "Repair Team",
  [USER_ROLES.CORPORATE]: "Corporate Customer"
});

export const USER_ROLES_ENUM = Object.freeze({
  [USER_ROLES.CUSTOMER]: 0,
  [USER_ROLES.ADMIN]: 1,
  [USER_ROLES.MANAGER]: 2,
  [USER_ROLES.REPAIR_TEAM]: 3,
  [USER_ROLES.CORPORATE]: 4
});

export const ADMIN_ROLE = [USER_ROLES.ADMIN];
export const CUSTOMER_ROLE = [USER_ROLES.CUSTOMER, USER_ROLES.CORPORATE];
export const BASIC_STAFF_ROLES = [...ADMIN_ROLE, USER_ROLES.MANAGER];
export const All_STAFF_ROLES = [...BASIC_STAFF_ROLES, USER_ROLES.REPAIR_TEAM];
export const All_ROLES = [...All_STAFF_ROLES, USER_ROLES.CUSTOMER];
export const ROLE_PRIORITY = [USER_ROLES.ADMIN, USER_ROLES.MANAGER, USER_ROLES.REPAIR_TEAM];

export const ADDRESS_TYPE_ENUM = Object.freeze({
  shop: 0,
  shipping: 1,
  customer: 2,
  billing: 3,
});

export const USER_STATUS_MAPPER = Object.freeze({
  ACTIVE: "active",
  INACTIVE: "inactive",
  ONBOARDING: "onboarding",
});

export const USER_STATUS_ENUM = Object.freeze({
  [USER_STATUS_MAPPER.ACTIVE]: 0,
  [USER_STATUS_MAPPER.INACTIVE]: 1,
  [USER_STATUS_MAPPER.ONBOARDING]: 2,
});

export const ORDER_TYPES = Object.freeze({
  IN_STORE: "in_store",
  ONLINE: "online",
});

export const ORDER_TYPE_ENUM = Object.freeze({
  [ORDER_TYPES.IN_STORE]: 0,
  [ORDER_TYPES.ONLINE]: 1,
});

export const ORDER_STATUS_ENUM = Object.freeze({
  new_order: 0,
  shipping: 1,
  in_progress: 2,
  delayed: 3,
  repaired: 4,
  delivered: 5,
  quote: 6,
});

export const SUBSCRIPTION_STATUS = Object.freeze({
  TRIAL: "trialing",
  ACTIVE: "active",
  INCOMPLETE: "incomplete",
  INCOMPLETE_EXPIRED: "incomplete_expired",
  PAST_DUE: "past_due",
  CANCELED: "canceled",
  UNPAID: "unpaid",
  PAUSED: "paused",
});

export const SUBSCRIPTION_STATUS_ENUM = Object.freeze({
  [SUBSCRIPTION_STATUS.TRIAL]: 0,
  [SUBSCRIPTION_STATUS.ACTIVE]: 1,
  [SUBSCRIPTION_STATUS.INCOMPLETE]: 2,
  [SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED]: 3,
  [SUBSCRIPTION_STATUS.PAST_DUE]: 4,
  [SUBSCRIPTION_STATUS.CANCELED]: 5,
  [SUBSCRIPTION_STATUS.UNPAID]: 6,
  [SUBSCRIPTION_STATUS.PAUSED]: 7,
});

export const ORDER_STATUS = Object.freeze({
  new_order: "New",
  shipping: "Shipping",
  in_progress: "In progress",
  delayed: "Delayed",
  repaired: "Repaired",
  delivered: "Delivered",
  quote: "Quote",
});

export const PAYMENT_STATUS = Object.freeze({
  INCOMPLETE: "in_complete",
  CAPTURED: "captured",
  SUCCEEDED: "succeeded",
  FAILED: "failed",
  CANCELED: "canceled",
  PENDING_REFUND: "pending_refund",
  REFUNDED: "refunded",
  PENDING: "pending",
  BILL_LATER: "bill_later",
  INVOICED: "invoiced",
});

export const PAYMENT_STATUS_ENUM = Object.freeze({
  [PAYMENT_STATUS.INCOMPLETE]: 0,
  [PAYMENT_STATUS.CAPTURED]: 1,
  [PAYMENT_STATUS.SUCCEEDED]: 2,
  [PAYMENT_STATUS.FAILED]: 3,
  [PAYMENT_STATUS.CANCELED]: 4,
  [PAYMENT_STATUS.PENDING_REFUND]: 5,
  [PAYMENT_STATUS.REFUNDED]: 6,
  [PAYMENT_STATUS.PENDING]: 7,
  [PAYMENT_STATUS.BILL_LATER]: 8,
  [PAYMENT_STATUS.INVOICED]: 9,
});

export const INVOICE_STATUS = Object.freeze({
  OPEN: 'open',
  PAID: 'paid',
  OVERDUE: 'overdue',
  FAILED: 'failed',
  CANCELED: 'canceled'
})

export const INVOICE_STATUS_ENUM = Object.freeze({
  [INVOICE_STATUS.OPEN]: 0,
  [INVOICE_STATUS.PAID]: 1,
  [INVOICE_STATUS.OVERDUE]: 2,
  [INVOICE_STATUS.FAILED]: 3,
  [INVOICE_STATUS.CANCELED]: 4,
});

export const PRODUCT_STATUS = Object.freeze({
  in_active: "Inactive",
  active: "Active",
  low_inventory: "Low inventory",
  in_stock: "In Stock",
  out_of_stock: "Out of stock",
});

export const CONVERSATION_STATUS = Object.freeze({
  ACTIVE: 'active',
  FLAG: 'flag',
  TRASH: 'trash',
});

export const CONVERSATION_TYPE = Object.freeze({
  INBOX: { key: 'Inbox', value: CONVERSATION_STATUS.ACTIVE },
  FLAGGED: { key: 'Flagged', value: CONVERSATION_STATUS.FLAG },
  SENT: { key: 'Sent', value: 'sent' },
  DRAFT: { key: 'Draft', value: 'draft' },
  TRASH: { key: 'Trash', value: CONVERSATION_STATUS.TRASH },
});

export const ORDER_CHAT_STATUS_ENUM = Object.freeze({
  [CONVERSATION_STATUS.ACTIVE]: 0,
  [CONVERSATION_STATUS.FLAG]: 1,
  [CONVERSATION_STATUS.TRASH]: 2,
});

export const CONVERSATION_TYPE_KEYS = Object.freeze({
  INBOX: CONVERSATION_TYPE.INBOX.key,
  FLAGGED: CONVERSATION_TYPE.FLAGGED.key,
  SENT: CONVERSATION_TYPE.SENT.key,
  DRAFT: CONVERSATION_TYPE.DRAFT.key,
  TRASH: CONVERSATION_TYPE.TRASH.key,
});

export const CONVERSATION_TYPE_VALUES = Object.freeze({
  INBOX: CONVERSATION_TYPE.INBOX.value,
  FLAGGED: CONVERSATION_TYPE.FLAGGED.value,
  SENT: CONVERSATION_TYPE.SENT.value,
  DRAFT: CONVERSATION_TYPE.DRAFT.value,
  TRASH: CONVERSATION_TYPE.TRASH.value,
});

export const ORDER_CHAT_TYPE_FILTERS = Object.freeze(
  Object.values(CONVERSATION_TYPE).map(({ key, value }) => ({ label: key, value }))
);

export const DISCOUNT_TYPES = {
  percentage: "percentage",
  amount: "amount",
};

export const CART_TYPES = {
  checkout: "checkout",
  quote: "quote",
};

export const PAYMENT_METHODS = {
  CASH: "cash",
  DEBIT_OR_CREDIT: "debitOrCredit",
  CARD_SCAN: "card_present",
};

export const POS_NEW_ORDER_STEPS = {
  ADD_ITEM: 0,
  ADD_DISCOUNT: 1,
  CREATE_NEW_DISCOUNT: 2,
  PAYMENT_METHOD: 3,
  SELECT_CUSTOMER: 4,
  CREATE_CUSTOMER: 5,
  CALCULATE_PAYMENT: 6,
  ORDER_SUCCESS: 7,
  CARD_PAYMENT: 8,
  SCAN_CARD: 9,
  ATTACH_CUSTOMER: 10,
  ORDER_CART: 11,
  SELECT_CORPORATE: 12,
};

export const EMPLOYEE_ROLES_OPTIONS = [
  { title: "Manager", value: "manager" },
  { title: "Repair Team", value: "repair_team" },
];

export const COUNTRIES = [
  { title: "United States", value: "US" },
  { title: "Canada", value: "CA" },
];

export const COMPANY_SIZE = [
  { title: "1-5 employees", value: "1-5" },
  { title: "10-50 employees", value: "10-50" },
  { title: "50-100 employees", value: "50-100" },
  { title: "100-200 employees", value: "100-200" },
];

export const CANADIAN_PROVINCE = [
  { title: "Alberta", value: "AB" },
  { title: "British Columbia", value: "BC" },
  { title: "Manitoba", value: "MB" },
  { title: "New Brunswick", value: "NB" },
  { title: "Newfoundland and Labrador", value: "NL" },
  { title: "Northwest Territories", value: "NT" },
  { title: "Nova Scotia", value: "NS" },
  { title: "Nunavut", value: "NU" },
  { title: "Ontario", value: "ON" },
  { title: "Prince Edward Island", value: "PE" },
  { title: "Quebec", value: "QC" },
  { title: "Saskatchewan", value: "SK" },
  { title: "Yukon", value: "YT" },
];

export const US_STATES = [
  { title: "Alabama", value: "AL" },
  { title: "Alaska", value: "AK" },
  { title: "Arizona", value: "AZ" },
  { title: "Arkansas", value: "AR" },
  { title: "California", value: "CA" },
  { title: "Colorado", value: "CO" },
  { title: "Connecticut", value: "CT" },
  { title: "Delaware", value: "DE" },
  { title: "District of Columbia", value: "DC" },
  { title: "Florida", value: "FL" },
  { title: "Georgia", value: "GA" },
  { title: "Hawaii", value: "HI" },
  { title: "Idaho", value: "ID" },
  { title: "Illinois", value: "IL" },
  { title: "Indiana", value: "IN" },
  { title: "Iowa", value: "IA" },
  { title: "Kansas", value: "KS" },
  { title: "Kentucky", value: "KY" },
  { title: "Louisiana", value: "LA" },
  { title: "Maine", value: "ME" },
  { title: "Maryland", value: "MD" },
  { title: "Massachusetts", value: "MA" },
  { title: "Michigan", value: "MI" },
  { title: "Minnesota", value: "MN" },
  { title: "Mississippi", value: "MS" },
  { title: "Missouri", value: "MO" },
  { title: "Montana", value: "MT" },
  { title: "Nebraska", value: "NE" },
  { title: "Nevada", value: "NV" },
  { title: "New Hampshire", value: "NH" },
  { title: "New Jersey", value: "NJ" },
  { title: "New Mexico", value: "NM" },
  { title: "New York", value: "NY" },
  { title: "North Carolina", value: "NC" },
  { title: "North Dakota", value: "ND" },
  { title: "Ohio", value: "OH" },
  { title: "Oklahoma", value: "OK" },
  { title: "Oregon", value: "OR" },
  { title: "Pennsylvania", value: "PA" },
  { title: "Rhode Island", value: "RI" },
  { title: "South Carolina", value: "SC" },
  { title: "South Dakota", value: "SD" },
  { title: "Tennessee", value: "TN" },
  { title: "Texas", value: "TX" },
  { title: "Utah", value: "UT" },
  { title: "Vermont", value: "VT" },
  { title: "Virginia", value: "VA" },
  { title: "Washington", value: "WA" },
  { title: "West Virginia", value: "WV" },
  { title: "Wisconsin", value: "WI" },
  { title: "Wyoming", value: "WY" },
];

export const STATES_PER_COUNTRY = {
  US: US_STATES,
  CA: CANADIAN_PROVINCE,
};

export const POS_TAB_LIST = [
  { label: "Services", value: "services" },
  { label: "Products", value: "products" },
  { label: "Options", value: "options" },
  { label: "Category Types", value: "category-types" },
];

export const ORDER_HISTORY_TAB_LIST = [
  { label: "All orders", value: "all-orders" },
  { label: "Quotes", value: "quotes" },
  { label: "Active orders", value: "active-orders" },
  { label: "Past orders", value: "past-orders" },
  // { label: "Messages", value: "messages" },
];

export const CUSTOMER_ADDRESS_TYPE_OPTIONS = [
  { title: "Shipping address", value: "shipping" },
  { title: "Billing address", value: "billing" },
];

export const INVOICE_HISTORY_TAB_LIST = [
  { label: "All invoices", value: "all" },
  { label: "Unpaid invoices", value: "unpaid" },
  { label: "Paid invoices", value: "paid" },
];

export const CUSTOMER_LIST_TABS = [
  { label: "Individual customers", value: "individual" },
  { label: "Corporate customers", value: "corporate" },
];

export const CUSTOMER_ORDER_DETAILS_TAB_LIST = [
  { label: "Order details", value: "all-details" },
];

export const CUSTOMER_DASHBOARD_TAB_LIST = [
  { label: "Orders", value: "my-orders", route: CUSTOMER_ROUTES.ORDERS_OVERVIEW },
  { label: "Invoices", value: "invoices", route: CUSTOMER_ROUTES.INVOICES_OVERVIEW },
  { label: "Messages", value: "messages", route: CUSTOMER_ROUTES.MESSAGES },
];

export const STRIPE_CONNECT_ACC_LINK_TYPES = {
  ONBOARDING: "account_onboarding",
};

export const TEAM_MEMBER_ROLES = [
  { title: "Admin", value: "admin" },
  { title: "Manager", value: "manager" },
  { title: "Repair Team", value: "repair_team" },
];

export const TAX_RATE_SPECIFICATIONS = [
  { title: "All Services", value: "all_services" },
  { title: "All Products", value: "all_products" },
];

export const LOGIN_TYPE = Object.freeze({
  STAFF: 'staff',
  CUSTOMER: 'customer'
})

export const BILLING_CYCLE = [
  { title: "Bi-monthly", value: "bi_monthly" },
  { title: "Monthly", value: "monthly" },
]

export const BILLING_METHOD = Object.freeze({
  SEND_INVOICE: "invoice",
  PAY_WITH_CARD_ON_FILE: "card_on_file",
});

export const BILLING_METHOD_OPTIONS = [
  { title: "Send Invoice", value: "invoice" },
  { title: "Pay with card on file", value: "card_on_file" },
];

export const UPS_TRACKING_URL = "https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=";

export const CHECKBOX_DISPLAY_IN_EMAIL = "Display in email";
export const UPLOAD_CUSTOMER_CSV_CONTENT  = `Full name,Email,Phone number,Last signup,Shipping address,Billing address\nJohn Doe,john.doe@example.com,1234567890,01/21/2023,"123 Main St, Anytown, NY 12345, USA","456 Elm St, Othertown, CA 67890, USA"`;
